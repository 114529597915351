import React from 'react'
import { Link } from 'gatsby'

export default function BackButton({text, path}) {
  return (
      <Link to={path}>
        <button className="back-btn">{text}</button>
      </Link>
  )
}
